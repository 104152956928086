<template>
<span>
    <b-row class="SpaceCardHome" v-if="!isMobile">
        <div :class="'col-md-'+card.size+' col-12 heightCard'" :data-id-card="card.id" v-for="card in AllCards" :key="card.id">
            <vue-aspect-ratio ar="16:4" width="100%">
                <a :href="card.link" target="_blank">
                    <img class="cardHome" :src="card.media.cdn_url" alt="img" loading="lazy">
                </a>
            </vue-aspect-ratio>
        </div>
    </b-row>
    <b-row class="SpaceCardHome spaceMobileCard" v-if="isMobile">
        <div :class="'col-md-12 col-12 heightCard'" :data-id-card="card.id" v-for="card in AllCards" :key="card.id">
            <vue-aspect-ratio ar="16:4" width="100%">
                <a :href="card.link" target="_blank">
                    <img class="cardHome" :src="card.media.cdn_url" alt="img" loading="lazy">
                </a>
            </vue-aspect-ratio>
        </div>
    </b-row>
</span>
</template>

<script>
import VueAspectRatio from "vue-aspect-ratio";

export default {
    props:['AllCards', 'isMobile'],
    components: {
        "vue-aspect-ratio": VueAspectRatio
    }
}
</script>

<style lang="scss">
.SpaceCardHome{
    margin: 0 auto;
    padding: 40px 0;
    .cardHome{
        width: 100%;
        // height: 100%;
        // object-fit: cover;
        border-radius: 5px;
    }
    .heightCard{
        margin-bottom: 10px;
        overflow: hidden;
        border-radius: 5px;
    }
    .col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col, .col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm, .col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md, .col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg, .col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl, .col-xl-auto{
        padding-right: 0px !important;
        padding-left: 0px !important;
    }
}

.spaceMobileCard{
    padding: 0 0;
}

</style>